import { defineStore } from 'pinia';
interface savedPosition {
    top?: number;
    left?: number;
    el?: Element | string;
    behavior?: 'auto' | 'instant' | 'smooth';
}
export const usePagePositionStore = defineStore({
    id: 'myPagePositionStore',
    state: () => ({ map: shallowRef(new Map<string, savedPosition>()) }),
    actions: {
        addSavedPosition(key: string, value: savedPosition) {
            this.map.set(key, value);
        },
        removeSavedPosition(key: string) {
            this.map.delete(key);
        }
    },
    getters: {
        getSavedPosition: state => (key: string | undefined) => {
            return key ? state.map.get(key) : undefined;
        },
        hasSavedPosition: state => (key: string | undefined) => {
            return key ? state.map.has(key) : false;
        }
    }
});
