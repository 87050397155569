<template>
    <component
        :is="$viewport.isLessThan('lg') ? 'aside' : 'header'"
        class="sticky top-0 h-18 bg-ui-bg/80 backdrop-blur-md px-3 z-20 transition-[top] duration-300"
        :class="[{ 'max-md:-top-18': scrollDown }]"
    >
        <UProgress
            v-if="$viewport.isGreaterOrEquals('lg')"
            v-show="isLoading"
            color="lolo"
            :ui="{ base: 'bg-transparent' }"
            :max="100"
            :model-value="progress"
            size="xs"
            class="absolute inset-x-0"
        />
        <nav class="text-md tracking-tight my-container flex items-center h-full font-bold text-gray-700">
            <UButtonLocale
                v-slot="{ isActive }"
                prefetch
                prefetch-on="interaction"
                to="/"
                size="xs"
                color="neutral"
                base-class="group text-ui-text-muted"
                variant="link"
                class="mt-1"
            >
                <UIcon name="my:logos" class="text-primary" size="2rem" />
                <span
                    class="font-custom tracking-tighter text-2xl font-bold mt-0.5 group-hover:text-ui-text"
                    :class="[
                        {
                            'decoration-primary underline decoration-3 underline-offset-3 text-ui-text-toned': isActive
                        }
                    ]"
                >
                    AirEtMontagne
                </span>
            </UButtonLocale>

            <span class="flex-1 max-w-10 min-w-1" />

            <div class="flex gap-0 pt-2 max-lg:hidden">
                <UButtonLocale
                    v-for="mainroute in mainRoutes(false).value"
                    v-show="mainroute.show"
                    :key="mainroute.to"
                    v-slot="{ isActive }"
                    :to="mainroute.to"
                    prefetch
                    prefetch-on="interaction"
                    size="md"
                    color="neutral"
                    active-color="primary"
                    base-class="focus-visible:ring-2 group text-ui-text-toned"
                    active-class="ring-primary-500/50"
                    inactive-class="ring-lolo-400/50"
                    variant="link"
                >
                    <UIcon
                        :name="mainroute.icon"
                        class="group-hover:text-ui-text-highlighted"
                        :class="[{ 'text-ui-text-highlighted': isActive }]"
                        size="1.4rem"
                    />
                    <span
                        class="text-base tracking-tighter font-semibold group-hover:text-ui-text-highlighted"
                        :class="[
                            {
                                'decoration-primary underline decoration-2 underline-offset-3 text-ui-text-highlighted': isActive
                            }
                        ]"
                    >
                        {{ $t(mainroute.text) }}
                    </span>
                </UButtonLocale>
            </div>

            <span class="flex-1 min-w-1" />

            <div class="mt-2">
                <UButtonLocale
                    v-if="!$viewport.isLessThan('sm')"
                    prefetch
                    prefetch-on="interaction"
                    class=""
                    base-class="px-5 font-bold text-md max-sm:hidden"
                    to="reserver"
                    color="primary"
                    variant="subtle"
                >
                    {{ $t('reserver') }}
                </UButtonLocale>
            </div>

            <span class="max-w-10 flex-1 min-w-1" />

            <!-- right items -->
            <div class="flex gap-0 pt-2">
                <AuthState>
                    <template #default="{ loggedIn, user }">
                        <UButtonLocale
                            v-if="loggedIn"
                            v-slot="{ isActive }"
                            active-color="primary"
                            :color="'neutral'"
                            variant="ghost"
                            to="user"
                            :aria-label="loggedIn ? 'mon compte' : $t('connexion')"
                        >
                            <UIcon :name="loggedIn ? 'ph:user' : 'ph:sign-in'" size="1.4rem" />
                            <span
                                class="text-ui-text text-sm font-semibold max-xl:hidden max-w-50 min-w-10 line-clamp-1 break-all"
                                :class="[
                                    {
                                        'decoration-primary underline decoration-2 underline-offset-4': isActive
                                    }
                                ]"
                            >
                                {{ loggedIn ? user?.email : $t('connexion') }}
                            </span>
                        </UButtonLocale>
                    </template>
                    <template #placeholder> ... </template>
                </AuthState>

                <UButtonLocale
                    v-slot="{ isActive }"
                    prefetch
                    prefetch-on="interaction"
                    to="cart"
                    size="md"
                    color="neutral"
                    variant="link"
                    aria-label="panier"
                    base-class="focus-visible:ring-2 group text-ui-text-toned"
                    active-class="ring-primary-500/50"
                    inactive-class="ring-lolo-400/50"
                >
                    <UIcon
                        name="ph:shopping-cart-simple"
                        class="group-hover:text-ui-text-highlighted"
                        :class="[{ 'text-ui-text-highlighted': isActive }]"
                        size="1.4rem"
                    />
                    <span
                        class="text-base font-semibold max-xl:hidden group-hover:text-ui-text-highlighted"
                        :class="[
                            {
                                'decoration-primary underline decoration-2 underline-offset-3 text-ui-text-highlighted': isActive
                            }
                        ]"
                    >
                        {{ $t('panier') }}
                    </span>
                </UButtonLocale>

                <UPopover :content="{ align: 'end', alignOffset: -10, sideOffset: 5 }">
                    <template #default="{}">
                        <UButton variant="ghost" color="lolo" aria-label="changer la langue">
                            <Icon name="ph:translate" size="1.5rem" />
                        </UButton>
                    </template>

                    <template #content>
                        <div class="w-40 text-base flex flex-col py-1 gap-1 bg-ui-bg-muted">
                            <SwitchLocalePathLink
                                v-for="locale in locales"
                                :key="locale.code"
                                class="block pl-6 py-2 hover:bg-ui-bg-accented top-auto"
                                :class="{ 'text-primary': currentLocale === locale.code }"
                                :locale="locale.code"
                            >
                                {{ locale.name }}
                            </SwitchLocalePathLink>
                        </div>
                    </template>
                </UPopover>
            </div>
        </nav>
        <!-- :class="[{ 'lg:outline-none': isAtTop || route.meta.showBreadcrumb }, { 'outline': !isAtTop }]" -->

        <div
            v-if="(!isAtTop && $viewport.isGreaterThan('md') && !route.meta.showBreadcrumb) || (!isAtTop && $viewport.isLessOrEquals('md'))"
            class="absolute bottom-0 inset-x-0 border-b border-ui-border-accented"
        />
    </component>

    <main class="min-h-svh">
        <div class="min-h-svh">
            <slot />
        </div>

        <div
            v-if="$viewport.isLessThan('sm')"
            class="sticky my-container transition-all duration-300 delay-100 h-0"
            :class="
                (initState && /^\/(fr|en)?$/.test(route.path)) || scrollDown
                    ? route.meta.showBreadcrumb
                        ? 'bottom-20'
                        : 'bottom-12'
                    : route.meta.showBreadcrumb
                      ? 'bottom-38'
                      : 'bottom-26'
            "
        >
            <NuxtLinkLocale
                to="reserver"
                class="absolute flex items-center justify-center backdrop-blur-sm transition-all duration-300 delay-100 ease-out overflow-clip"
                :class="[
                    expendMobileResa
                        ? [
                              'w-[calc(100%-var(--spacing)*16)] h-14 my-4 mx-8 right-0 bg-ui-bg text-primary rounded-2xl translate-y-px outline outline-ui-outline',
                              route.meta.showBreadcrumb ? '-top-8' : '-top-12'
                          ]
                        : [
                              'w-14 h-14 rounded-3xl right-5 bg-ui-bg-accented/70 active:bg-primary-500/30 text-primary outline outline-ui-outline',
                              route.meta.showBreadcrumb ? '-top-[1.6rem]' : '-top-7'
                          ]
                ]"
                aria-label="reserver"
            >
                <Transition
                    enter-active-class="duration-100 delay-100 ease-out"
                    enter-from-class="transform opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="duration-100 delay-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="transform opacity-0"
                >
                    <Icon v-if="!expendMobileResa || !isAtTop" class="absolute text-primary" name="ph:plus-bold" size="1.6rem" />
                    <span v-else class="inset-0 text-2xl tracking-normal text-nowrap"> Réserver dès aujourd'hui </span>
                </Transition>
            </NuxtLinkLocale>
        </div>
    </main>

    <footer class="h-96 bg-slate-700 pt-12 px-4">footer</footer>

    <header
        v-if="$viewport.isLessThan('lg')"
        class="transition-[bottom] duration-300 sticky bottom-0 h-14 bg-ui-bg outline outline-ui-outline inset-x-0 px-3 z-20"
        :class="[{ '!-bottom-14': (initState && /^\/(fr|en)?$/.test(route.path)) || scrollDown }, { 'outline-none': route.meta.showBreadcrumb }]"
    >
        <nav class="h-full flex justify-around tracking-tight font-semibold text-xs md:text-sm my-container">
            <UButtonLocale
                v-for="mainroute in mainRoutes(true).value"
                :key="mainroute.to"
                v-slot="{ isActive }"
                :to="mainroute.to"
                variant="ghost"
                color="neutral"
                active-color="neutral"
                block
                class="flex-1 flex"
                base-class="  flex flex-1 flex-col sm:flex-row items-center sm:items-start sm:pt-2.5 p-0 gap-1 sm:gap-2.5 group font-semibold text-xs md:text-sm relative overflow-hidden"
            >
                <div class="inset-0 rounded-3xl absolute scale-y-150 group-active:bg-primary-500/20 transition-colors" />
                <Icon :name="mainroute.icon" class="size-5 transition-colors" :class="{ 'text-primary': isActive }" size="1.4rem" />
                <div class="sm:pt-0.5" :class="{ 'text-ui-text-highlighted': isActive }">{{ $t(mainroute.text) }}</div>
            </UButtonLocale>
        </nav>
        <UProgress
            v-show="isLoading"
            color="primary"
            :max="100"
            :ui="{ base: 'bg-transparent' }"
            :model-value="progress"
            size="xs"
            class="absolute top-0 inset-x-0"
        />
    </header>
</template>

<script lang="ts" setup>
const { $viewport } = useNuxtApp();
console.log($viewport.breakpoint.value);
const { locale: currentLocale, locales } = useI18n();
const route = useRoute();
const { scrollDown, isAtTop, initState } = useScrollDirection();
const { progress, isLoading } = useLoadingIndicator();
const expendMobileResa = computed(() => route.meta.expendMobileResa && isAtTop.value);

const mainRoutes = (short: boolean) =>
    ref<
        Array<{
            to: string;
            icon: string;
            iconBold: string;
            text: string;
            show?: boolean;
        }>
    >([
        {
            to: 'activites',
            icon: 'ph:activity',
            iconBold: 'ph:activity-bold',
            show: true,
            text: short ? 'activitesShort' : 'activites'
        },
        {
            to: '/bon-cadeau',
            icon: 'ph:gift',
            iconBold: 'ph:gift-bold',
            show: false,
            text: 'cadeau'
        },
        {
            to: 'articles',
            icon: 'ph:newspaper-clipping',
            iconBold: 'ph:newspaper-clipping-bold',
            show: true,
            text: 'articles'
        },
        {
            to: 'galerie',
            icon: 'ph:images-square',
            iconBold: 'ph:images-square-bold',
            show: true,
            text: 'galerie'
        },
        {
            to: 'contact',
            icon: 'ph:phone',
            iconBold: 'ph:phone-bold',
            show: true,
            text: short ? 'contactShort' : 'contact'
        }
    ]);
</script>

<style></style>
