import { LazySignInModal } from '#components';

//import SingInModal from "~/components/sign-in/modal.vue"

const popA = [false];
let isPop = false;
if (import.meta.client) {
    window.addEventListener('popstate', () => {
        console.log('middleware Navigation popstate', isPop);
        isPop = true;
    });
}

export default defineNuxtRouteMiddleware(async (to, from) => {
    console.log('middleware Navigation pop', isPop);
    console.log('auth middleware', from.fullPath, to.fullPath);

    if (import.meta.server && !to.meta.authPage) return;

    popA.push(isPop ? true : false);
    const modal = useModal();

    if (modal.isOpen.value && !to.meta.authPage) {
        console.log('middleware', from.fullPath, to.fullPath);
        modal.close();
        const p = popA.every(Boolean);
        popA.shift();
        isPop = false;
        console.log('middleware Navigation pop modal' + p);
        if (p) return;
        return abortNavigation();
        //abortNavigation() //navigateTo(from.meta.authPage? to : from)
    }
    popA.shift();
    isPop = false;

    if (!to.meta.authPage) return;

    const { loggedIn } = useUserSession();

    if (loggedIn.value) return;

    if ((import.meta.server || useNuxtApp().isHydrating) && !loggedIn.value) {
        return navigateTo({ path: '/sign-in', query: { redirect: to.fullPath } });
    }

    useLoadingIndicator().clear();
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const res = await new Promise<() => any>((res, err) => {
            modal.open(LazySignInModal, {
                description: 'And you can even provide a description!',

                onRedirect: (path: string) => {
                    res(() => navigateTo(path));
                },
                onSuccess: () => {
                    console.log('on success middleware');
                    res(() => '');
                },
                onClose: () => {
                    console.log('on close middleware');
                    err();
                }
            });
        });
        modal.close();
        return res();
    } catch {
        console.log('middleware catch abort');

        return abortNavigation('require user credencials');
    }
});
