<template>
    <UModal ref="mod" :dismissible="dismissible" class="font-nunito" :ui="{ content: 'sm:max-w-sm' }" @update:open="e => (!e ? emit('close') : null)">
        <template #content>
            <UCard class="pt-4">
                <SignInComp
                    @redirect="emit('redirect', $event)"
                    @success="
                        () => {
                            dismissible = true;
                            emit('success');
                        }
                    "
                    @request-login="dismissible = false"
                    @request-echec="dismissible = true"
                />
            </UCard>
        </template>
    </UModal>
</template>

<script lang="ts" setup>
const mod = useTemplateRef('mod');
const dismissible = ref(true);

const emit = defineEmits<{
    success: [];
    redirect: [fullpath: string];
    close: [];
}>();
</script>

<style></style>
