export default defineAppConfig({
    ui: {
        colors: {
            primary: 'rose',
            secondary: 'sky',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            lolo: 'lolo' as any,
            neutral: 'neutral'
        },
        // primary: 'rose',
        // secondary: 'amber',
        // gray: 'slate',

        popover: {
            shadow: 'shadow-lg',
            ring: 'ring-gray-300',
            rounded: 'rounded',
            transition: {
                enterActiveClass: 'duration-100',
                enterFromClass: 'opacity-0 -translate-y-2 scale-95',
                leaveActiveClass: 'duration-700'
            }
        },
        input: {
            color: {
                white: {
                    outline: 'ring-gray-500 bg-white'
                }
            },
            variant: {
                outline: ' bg-red-50/90 shadow'
            }
        },

        progress: {
            slots: {
                base: 'bg-ui-bg'
            }
        },

        modal: {
            slots: { overlay: 'backdrop-blur-lg backdrop-saturate-50 bg-ui-bg/0' },
            variants: {
                fullscreen: {
                    false: {
                        //content: 'max-w-lg w-[95vw] h-auto rounded-[calc(var(--ui-radius)*2)] shadow-lg ring'
                        content:
                            'sm:max-w-lg w-[100vw]  max-sm:rounded-t-[calc(var(--ui-radius)*2)] shadow-lg ring max-sm:translate-y-0 max-sm:top-auto max-sm:bottom-0 h-auto'
                    }
                }
            }
        }
    }
});
