export default <T extends keyof typeof AppPermissions>(
    type: T,
    permissions: keyof (typeof AppPermissions)[T] | Array<keyof (typeof AppPermissions)[T]>,
    oneOf: boolean = false
) =>
    defineNuxtRouteMiddleware((to, from) => {
        const { hasPermissions, hasOneOfPermissions } = useAppPermissions();
        if (!Array.isArray(permissions)) permissions = [permissions];
        if (oneOf ? hasOneOfPermissions(type, ...permissions) : !hasPermissions(type, ...permissions)) {
            useToast().add({
                title: 'Oups',
                description: "vous n'avez pas les autorisations pour acceder à cette page"
            });

            return to.fullPath === from.fullPath
                ? abortNavigation({ message: "vous n'avez pas les autorisations pour acceder à cette page", statusCode: 401, stack: '' })
                : navigateTo(from, { replace: true, redirectCode: 401 });
        }
    });
