import { usePagePositionStore } from '~/store/storePagePosition';

export default defineNuxtPlugin({
    enforce: 'post',
    name: 'savePagePositionPlugin',
    async setup(nuxtApp) {
        const store = usePagePositionStore();

        nuxtApp.vueApp.config.globalProperties.$router.beforeEach((to, from) => {
            // console.log('plugin aferteach ', from.name, to.name, performance.now())
            // console.log('-------- ', !!from.name, !!to.name, to.meta.saveScrollPosition, !!from.name, from.meta.saveScrollPosition)
            // console.log('-------- ', !!from.name && from.meta.saveScrollPosition)

            if (!!from.name && from.meta.saveScrollPosition) {
                // console.log('plugin aferteach save position', to.name, from.name, performance.now())
                store.addSavedPosition(from.name.toString(), { top: window.scrollY });
            }
        });
    }
    // hooks: {
    //     'page:finish': async () => {
    //         console.log("Page finished loading..", performance.now());
    //     }
    // }
});
