
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexWIX1oIpZw5Meta } from "/opt/buildhome/repo/pages/(activites)/activites/index.vue?macro=true";
import { default as parapentenSYamnXhJeMeta } from "/opt/buildhome/repo/pages/(activites)/activites/parapente.vue?macro=true";
import { default as indexG2hwg6eTpdMeta } from "/opt/buildhome/repo/pages/(activites)/activites/ski/index.vue?macro=true";
import { default as indexyAHPLkuHLxMeta } from "/opt/buildhome/repo/pages/(activites)/activites/ski/randonnee/index.vue?macro=true";
import { default as randonneepIGeNUPHYqMeta } from "/opt/buildhome/repo/pages/(activites)/activites/ski/randonnee.vue?macro=true";
import { default as skiLzfqKhNwMYMeta } from "/opt/buildhome/repo/pages/(activites)/activites/ski.vue?macro=true";
import { default as activiteslfcwCTs6yTMeta } from "/opt/buildhome/repo/pages/(activites)/activites.vue?macro=true";
import { default as forgot_45passwordOZnQ9ajrM1Meta } from "/opt/buildhome/repo/pages/(auth)/(password)/forgot-password.vue?macro=true";
import { default as reset_45password2E3r9qa9cfMeta } from "/opt/buildhome/repo/pages/(auth)/(password)/reset-password.vue?macro=true";
import { default as sign_45inNgbTQQkQApMeta } from "/opt/buildhome/repo/pages/(auth)/(password)/sign-in.vue?macro=true";
import { default as sign_45upvof3EFkeE7Meta } from "/opt/buildhome/repo/pages/(auth)/(password)/sign-up.vue?macro=true";
import { default as webauthnNl7asz9hdqMeta } from "/opt/buildhome/repo/pages/(auth)/(sign-in)/webauthn.vue?macro=true";
import { default as indexfQDeoyx9sDMeta } from "/opt/buildhome/repo/pages/(galerie)/galerie/index.vue?macro=true";
import { default as uploadpiccV2FGGH57iMeta } from "/opt/buildhome/repo/pages/(galerie)/galerie/uploadpic.vue?macro=true";
import { default as galerie4deRMIVDauMeta } from "/opt/buildhome/repo/pages/(galerie)/galerie.vue?macro=true";
import { default as indexYbsOqsbcgOMeta } from "/opt/buildhome/repo/pages/(user)/user/activites/index.vue?macro=true";
import { default as activitesqJctzU0jV5Meta } from "/opt/buildhome/repo/pages/(user)/user/activites.vue?macro=true";
import { default as indexls7occOYWVMeta } from "/opt/buildhome/repo/pages/(user)/user/index.vue?macro=true";
import { default as planningsVyJwqEkVdMeta } from "/opt/buildhome/repo/pages/(user)/user/planning.vue?macro=true";
import { default as indexs8s89pyLfDMeta } from "/opt/buildhome/repo/pages/(user)/user/prix/index.vue?macro=true";
import { default as prixTMhWJlOUvVMeta } from "/opt/buildhome/repo/pages/(user)/user/prix.vue?macro=true";
import { default as indexa08lVjUbtTMeta } from "/opt/buildhome/repo/pages/(user)/user/produits/index.vue?macro=true";
import { default as produitsuA1xaubNxvMeta } from "/opt/buildhome/repo/pages/(user)/user/produits.vue?macro=true";
import { default as indexu4JqaMzyttMeta } from "/opt/buildhome/repo/pages/(user)/user/tva/index.vue?macro=true";
import { default as tvaJG4bHJvqo0Meta } from "/opt/buildhome/repo/pages/(user)/user/tva.vue?macro=true";
import { default as userFR7HLSEblhMeta } from "/opt/buildhome/repo/pages/(user)/user.vue?macro=true";
import { default as aboutcGvMViY0SnMeta } from "/opt/buildhome/repo/pages/about.vue?macro=true";
import { default as articlesrUijrSIq5yMeta } from "/opt/buildhome/repo/pages/articles.vue?macro=true";
import { default as bon_45cadeaucyYq5PIpg5Meta } from "/opt/buildhome/repo/pages/bon-cadeau.vue?macro=true";
import { default as cartltOYTvmzvgMeta } from "/opt/buildhome/repo/pages/cart.vue?macro=true";
import { default as conditionsN06e2dG6S9Meta } from "/opt/buildhome/repo/pages/conditions.vue?macro=true";
import { default as confidentialite6PEsCfFDVUMeta } from "/opt/buildhome/repo/pages/confidentialite.vue?macro=true";
import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as reserverJZaHHVOftpMeta } from "/opt/buildhome/repo/pages/reserver.vue?macro=true";
import { default as uploadImages88pCRhuPloMeta } from "/opt/buildhome/repo/pages/uploadImages.vue?macro=true";
export default [
  {
    name: activiteslfcwCTs6yTMeta?.name,
    path: "/activites",
    meta: activiteslfcwCTs6yTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites.vue"),
    children: [
  {
    name: "activites___fr___default",
    path: "",
    meta: indexWIX1oIpZw5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/index.vue")
  },
  {
    name: "activites-parapente___fr___default",
    path: "parapente",
    meta: parapentenSYamnXhJeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/parapente.vue")
  },
  {
    name: skiLzfqKhNwMYMeta?.name,
    path: "ski",
    meta: skiLzfqKhNwMYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski.vue"),
    children: [
  {
    name: "activites-ski___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/index.vue")
  },
  {
    name: randonneepIGeNUPHYqMeta?.name,
    path: "randonnee",
    meta: randonneepIGeNUPHYqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/randonnee.vue"),
    children: [
  {
    name: "activites-ski-randonnee___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/randonnee/index.vue")
  }
]
  }
]
  }
]
  },
  {
    name: activiteslfcwCTs6yTMeta?.name,
    path: "/fr/activites",
    meta: activiteslfcwCTs6yTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites.vue"),
    children: [
  {
    name: "activites___fr",
    path: "",
    meta: indexWIX1oIpZw5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/index.vue")
  },
  {
    name: "activites-parapente___fr",
    path: "parapente",
    meta: parapentenSYamnXhJeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/parapente.vue")
  },
  {
    name: skiLzfqKhNwMYMeta?.name,
    path: "ski",
    meta: skiLzfqKhNwMYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski.vue"),
    children: [
  {
    name: "activites-ski___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/index.vue")
  },
  {
    name: randonneepIGeNUPHYqMeta?.name,
    path: "randonnee",
    meta: randonneepIGeNUPHYqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/randonnee.vue"),
    children: [
  {
    name: "activites-ski-randonnee___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/randonnee/index.vue")
  }
]
  }
]
  }
]
  },
  {
    name: activiteslfcwCTs6yTMeta?.name,
    path: "/en/activities",
    meta: activiteslfcwCTs6yTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites.vue"),
    children: [
  {
    name: "activites___en",
    path: "",
    meta: indexWIX1oIpZw5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/index.vue")
  },
  {
    name: "activites-parapente___en",
    path: "paragliding",
    meta: parapentenSYamnXhJeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/parapente.vue")
  },
  {
    name: skiLzfqKhNwMYMeta?.name,
    path: "ski",
    meta: skiLzfqKhNwMYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski.vue"),
    children: [
  {
    name: "activites-ski___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/index.vue")
  },
  {
    name: randonneepIGeNUPHYqMeta?.name,
    path: "randonnee",
    meta: randonneepIGeNUPHYqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/randonnee.vue"),
    children: [
  {
    name: "activites-ski-randonnee___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(activites)/activites/ski/randonnee/index.vue")
  }
]
  }
]
  }
]
  },
  {
    name: "forgot-password___fr___default",
    path: "/forgot-password",
    meta: forgot_45passwordOZnQ9ajrM1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/forgot-password.vue")
  },
  {
    name: "forgot-password___fr",
    path: "/fr/forgot-password",
    meta: forgot_45passwordOZnQ9ajrM1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/forgot-password.vue")
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    meta: forgot_45passwordOZnQ9ajrM1Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/forgot-password.vue")
  },
  {
    name: "reset-password___fr___default",
    path: "/reset-password",
    meta: reset_45password2E3r9qa9cfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/reset-password.vue")
  },
  {
    name: "reset-password___fr",
    path: "/fr/reset-password",
    meta: reset_45password2E3r9qa9cfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/reset-password.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: reset_45password2E3r9qa9cfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/reset-password.vue")
  },
  {
    name: "sign-in___fr___default",
    path: "/sign-in",
    meta: sign_45inNgbTQQkQApMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-in.vue")
  },
  {
    name: "sign-in___fr",
    path: "/fr/sign-in",
    meta: sign_45inNgbTQQkQApMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-in.vue")
  },
  {
    name: "sign-in___en",
    path: "/en/sign-in",
    meta: sign_45inNgbTQQkQApMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-in.vue")
  },
  {
    name: "sign-up___fr___default",
    path: "/sign-up",
    meta: sign_45upvof3EFkeE7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-up.vue")
  },
  {
    name: "sign-up___fr",
    path: "/fr/sign-up",
    meta: sign_45upvof3EFkeE7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-up.vue")
  },
  {
    name: "sign-up___en",
    path: "/en/sign-up",
    meta: sign_45upvof3EFkeE7Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(auth)/(password)/sign-up.vue")
  },
  {
    name: "webauthn___fr___default",
    path: "/webauthn",
    component: () => import("/opt/buildhome/repo/pages/(auth)/(sign-in)/webauthn.vue")
  },
  {
    name: "webauthn___fr",
    path: "/fr/webauthn",
    component: () => import("/opt/buildhome/repo/pages/(auth)/(sign-in)/webauthn.vue")
  },
  {
    name: "webauthn___en",
    path: "/en/webauthn",
    component: () => import("/opt/buildhome/repo/pages/(auth)/(sign-in)/webauthn.vue")
  },
  {
    name: galerie4deRMIVDauMeta?.name,
    path: "/galerie",
    meta: galerie4deRMIVDauMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie.vue"),
    children: [
  {
    name: "galerie___fr___default",
    path: "",
    meta: indexfQDeoyx9sDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/index.vue")
  },
  {
    name: "galerie-uploadpic___fr___default",
    path: "ajouter",
    meta: uploadpiccV2FGGH57iMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/uploadpic.vue")
  }
]
  },
  {
    name: galerie4deRMIVDauMeta?.name,
    path: "/fr/galerie",
    meta: galerie4deRMIVDauMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie.vue"),
    children: [
  {
    name: "galerie___fr",
    path: "",
    meta: indexfQDeoyx9sDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/index.vue")
  },
  {
    name: "galerie-uploadpic___fr",
    path: "ajouter",
    meta: uploadpiccV2FGGH57iMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/uploadpic.vue")
  }
]
  },
  {
    name: galerie4deRMIVDauMeta?.name,
    path: "/en/gallery",
    meta: galerie4deRMIVDauMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie.vue"),
    children: [
  {
    name: "galerie___en",
    path: "",
    meta: indexfQDeoyx9sDMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/index.vue")
  },
  {
    name: "galerie-uploadpic___en",
    path: "add",
    meta: uploadpiccV2FGGH57iMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(galerie)/galerie/uploadpic.vue")
  }
]
  },
  {
    name: userFR7HLSEblhMeta?.name,
    path: "/user",
    meta: userFR7HLSEblhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user.vue"),
    children: [
  {
    name: activitesqJctzU0jV5Meta?.name,
    path: "activites",
    meta: activitesqJctzU0jV5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/activites.vue"),
    children: [
  {
    name: "user-activites___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/activites/index.vue")
  }
]
  },
  {
    name: "user___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/index.vue")
  },
  {
    name: "user-planning___fr___default",
    path: "planning",
    meta: planningsVyJwqEkVdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/planning.vue")
  },
  {
    name: prixTMhWJlOUvVMeta?.name,
    path: "prix",
    meta: prixTMhWJlOUvVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/prix.vue"),
    children: [
  {
    name: "user-prix___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/prix/index.vue")
  }
]
  },
  {
    name: produitsuA1xaubNxvMeta?.name,
    path: "produits",
    meta: produitsuA1xaubNxvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/produits.vue"),
    children: [
  {
    name: "user-produits___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/produits/index.vue")
  }
]
  },
  {
    name: tvaJG4bHJvqo0Meta?.name,
    path: "tva",
    meta: tvaJG4bHJvqo0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/tva.vue"),
    children: [
  {
    name: "user-tva___fr___default",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/tva/index.vue")
  }
]
  }
]
  },
  {
    name: userFR7HLSEblhMeta?.name,
    path: "/fr/user",
    meta: userFR7HLSEblhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user.vue"),
    children: [
  {
    name: activitesqJctzU0jV5Meta?.name,
    path: "activites",
    meta: activitesqJctzU0jV5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/activites.vue"),
    children: [
  {
    name: "user-activites___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/activites/index.vue")
  }
]
  },
  {
    name: "user___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/index.vue")
  },
  {
    name: "user-planning___fr",
    path: "planning",
    meta: planningsVyJwqEkVdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/planning.vue")
  },
  {
    name: prixTMhWJlOUvVMeta?.name,
    path: "prix",
    meta: prixTMhWJlOUvVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/prix.vue"),
    children: [
  {
    name: "user-prix___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/prix/index.vue")
  }
]
  },
  {
    name: produitsuA1xaubNxvMeta?.name,
    path: "produits",
    meta: produitsuA1xaubNxvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/produits.vue"),
    children: [
  {
    name: "user-produits___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/produits/index.vue")
  }
]
  },
  {
    name: tvaJG4bHJvqo0Meta?.name,
    path: "tva",
    meta: tvaJG4bHJvqo0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/tva.vue"),
    children: [
  {
    name: "user-tva___fr",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/tva/index.vue")
  }
]
  }
]
  },
  {
    name: userFR7HLSEblhMeta?.name,
    path: "/en/user",
    meta: userFR7HLSEblhMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user.vue"),
    children: [
  {
    name: activitesqJctzU0jV5Meta?.name,
    path: "activites",
    meta: activitesqJctzU0jV5Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/activites.vue"),
    children: [
  {
    name: "user-activites___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/activites/index.vue")
  }
]
  },
  {
    name: "user___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/index.vue")
  },
  {
    name: "user-planning___en",
    path: "planning",
    meta: planningsVyJwqEkVdMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/planning.vue")
  },
  {
    name: prixTMhWJlOUvVMeta?.name,
    path: "prix",
    meta: prixTMhWJlOUvVMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/prix.vue"),
    children: [
  {
    name: "user-prix___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/prix/index.vue")
  }
]
  },
  {
    name: produitsuA1xaubNxvMeta?.name,
    path: "produits",
    meta: produitsuA1xaubNxvMeta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/produits.vue"),
    children: [
  {
    name: "user-produits___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/produits/index.vue")
  }
]
  },
  {
    name: tvaJG4bHJvqo0Meta?.name,
    path: "tva",
    meta: tvaJG4bHJvqo0Meta || {},
    component: () => import("/opt/buildhome/repo/pages/(user)/user/tva.vue"),
    children: [
  {
    name: "user-tva___en",
    path: "",
    component: () => import("/opt/buildhome/repo/pages/(user)/user/tva/index.vue")
  }
]
  }
]
  },
  {
    name: "about___fr___default",
    path: "/about",
    meta: aboutcGvMViY0SnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "about___fr",
    path: "/fr/about",
    meta: aboutcGvMViY0SnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: aboutcGvMViY0SnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/about.vue")
  },
  {
    name: "articles___fr___default",
    path: "/articles",
    meta: articlesrUijrSIq5yMeta || {},
    component: () => import("/opt/buildhome/repo/pages/articles.vue")
  },
  {
    name: "articles___fr",
    path: "/fr/articles",
    meta: articlesrUijrSIq5yMeta || {},
    component: () => import("/opt/buildhome/repo/pages/articles.vue")
  },
  {
    name: "articles___en",
    path: "/en/articles",
    meta: articlesrUijrSIq5yMeta || {},
    component: () => import("/opt/buildhome/repo/pages/articles.vue")
  },
  {
    name: "bon-cadeau___fr___default",
    path: "/bon-cadeau",
    component: () => import("/opt/buildhome/repo/pages/bon-cadeau.vue")
  },
  {
    name: "bon-cadeau___fr",
    path: "/fr/bon-cadeau",
    component: () => import("/opt/buildhome/repo/pages/bon-cadeau.vue")
  },
  {
    name: "bon-cadeau___en",
    path: "/en/gift-card",
    component: () => import("/opt/buildhome/repo/pages/bon-cadeau.vue")
  },
  {
    name: "cart___fr___default",
    path: "/panier",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "cart___fr",
    path: "/fr/panier",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "conditions___fr___default",
    path: "/conditions",
    component: () => import("/opt/buildhome/repo/pages/conditions.vue")
  },
  {
    name: "conditions___fr",
    path: "/fr/conditions",
    component: () => import("/opt/buildhome/repo/pages/conditions.vue")
  },
  {
    name: "conditions___en",
    path: "/en/conditions",
    component: () => import("/opt/buildhome/repo/pages/conditions.vue")
  },
  {
    name: "confidentialite___fr___default",
    path: "/confidentialite",
    component: () => import("/opt/buildhome/repo/pages/confidentialite.vue")
  },
  {
    name: "confidentialite___fr",
    path: "/fr/confidentialite",
    component: () => import("/opt/buildhome/repo/pages/confidentialite.vue")
  },
  {
    name: "confidentialite___en",
    path: "/en/confidentialite",
    component: () => import("/opt/buildhome/repo/pages/confidentialite.vue")
  },
  {
    name: "contact___fr___default",
    path: "/nous-contacter",
    meta: contactupLWW5VsuQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/nous-contacter",
    meta: contactupLWW5VsuQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    meta: contactupLWW5VsuQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/contact.vue")
  },
  {
    name: "index___fr___default",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "reserver___fr___default",
    path: "/reserver",
    meta: reserverJZaHHVOftpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reserver.vue")
  },
  {
    name: "reserver___fr",
    path: "/fr/reserver",
    meta: reserverJZaHHVOftpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reserver.vue")
  },
  {
    name: "reserver___en",
    path: "/en/book",
    meta: reserverJZaHHVOftpMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reserver.vue")
  },
  {
    name: "uploadImages___fr___default",
    path: "/uploadImages",
    component: () => import("/opt/buildhome/repo/pages/uploadImages.vue")
  },
  {
    name: "uploadImages___fr",
    path: "/fr/uploadImages",
    component: () => import("/opt/buildhome/repo/pages/uploadImages.vue")
  },
  {
    name: "uploadImages___en",
    path: "/en/uploadImages",
    component: () => import("/opt/buildhome/repo/pages/uploadImages.vue")
  }
]