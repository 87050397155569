<template>
    <header class="sticky top-0 h-18 bg-ui-bg outline outline-ui-outline-accented px-3 z-10" :class="[{ '!outline-none': isAtTop }]">
        <nav class="text-sm tracking-tight my-container flex items-center h-full">
            <NuxtLinkLocale to="/" exact-active-class="text-primary underline" class="flex items-center gap-2 hover:underline underline-offset-4">
                <Icon name="my:logos" class="text-primary" size="2rem" />
                <span class="font-custom tracking-tighter text-2xl font-bold pt-1 text-ui-text">AirEtMontagne</span>
            </NuxtLinkLocale>

            <span class="flex-1 max-w-12 min-w-1" />

            <slot name="test" />
        </nav>
    </header>
    <div class="bg-ui-bg-muted">
        <main class="min-h-[calc(100svh-3.5rem)] my-container grid sm:items-center">
            <div class="sm:max-h-[45rem] sm:h-[35rem] sm:min-h-[35rem] flex flex-col">
                <!--  TODO -->
                <div class="sm:hidden h-1/4 min-h-28 bg-gradient-to-b from-lolo-600 to-lolo-400 sm:to-lolo-300">illustration à venir</div>
                <div
                    class="flex-1 flex flex-col h-full sm:w-90 sm:my-4 sm:mx-auto pt-8 pb-3 bg-ui-bg sm:rounded-lg sm:border border-ui-border sm:shadow-md"
                >
                    <slot />
                </div>
            </div>

            <!-- <div
            class="size-full sm:max-h-[45rem] sm:h-fit sm:min-h-[35rem] grid justify-center items-center bg-amber-500">

            

            <div
                class="select-none h-3/4  sm:max-w-96 flex-1  bg-gray-400 sm:rounded-lg sm:border sm:shadow ">
                <slot />
            </div>

        </div>-->
            <div class="max-sm:hidden h-0" />
        </main>
    </div>
    <footer class="h-96 bg-slate-700 pt-12 px-4">footer</footer>
</template>

<script lang="ts" setup>
const { isAtTop } = useScrollDirection();
</script>

<style></style>
