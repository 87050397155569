<template>
    <div class="h-[60vh] md:h-[70vh] min-h-100">
        <AnimationParallax>
            <IntroSlide :images="images" @index-change="indeChange" />
        </AnimationParallax>

        <div class="absolute inset-x-0 bottom-3/5">
            <div class="my-container px-3">
                <TexteIn :index="indexslide" :duration="4700" :content="slideContent" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TexteIn, { type ContentSlide } from '~/components/animation/TexteIn.vue';
import IntroSlide, { type ImageSlider } from '~/components/slides/IntroSlide.vue';

// const parallax = ref<HTMLElement | null>(null);
// const { isScrolling, isInViewport } = useScroll(parallax);
const indexslide = ref(0);

const images: ImageSlider[] = [
    { src: 'IMG_20231223_170128.jpg', preload: true },
    { src: 'IMG_20231225_155757.jpg' },
    { src: 'IMG_20230125_110948.jpg', gravity: { xxl: 'auto' } }
];

const slideContent: ContentSlide[] = [
    {
        title: 'Vols en parapente',
        description: "Texte d'introduction de l'activité",
        buttonText: 'Découvrir',
        buttonLink: '/activites/parapente'
    },
    {
        title: 'Ski hors piste',
        description: "Texte d'introduction de l'activité",
        buttonText: 'Découvrir',
        buttonLink: '/activites/ski'
    },
    {
        title: 'Ski de Randonnée',
        description: "Texte d'introduction de l'activité",
        buttonText: 'Découvrir',
        buttonLink: '/activites/randonnee'
    }
];

function indeChange(index: number) {
    indexslide.value = index;
}
</script>
