<template>
    <div class="relative overflow-hidden size-full">
        <div ref="container" class="will-change-transform size-full">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        speed?: number;
    }>(),
    {
        speed: 5
    }
);

const container = useTemplateRef('container');

const handleScroll = () => {
    requestAnimationFrame(() => {
        if (!container.value) return;
        const scrollPosition = window.scrollY;
        const offset = (scrollPosition * (props.speed * 0.01)).toFixed(1);
        container.value.style.transform = `translateY(${offset}%)`;
    });
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>
