<template>
    <div class="inline-block group transition-opacity duration-300" :class="anim ? 'anim' : 'opacity-0'">
        <div class="overflow-hidden">
            <div class="group-[.anim]:animate-slide-title font-custom tracking-tight pb-2 pr-1 text-white text-4xl md:text-7xl font-bold">
                {{ content[indexContent].title }}
            </div>
        </div>
        <div class="bg-white h-[3px] rounded-full group-[.anim]:animate-slide-bar flex">
            <div class="size-full bg-primary group-[.anim]:animate-slide-timer" :style="styleTimer" />
        </div>
        <div class="overflow-hidden">
            <div class="group-[.anim]:animate-slide-desc">
                <div class="font-bold text-white text-xl md:text-2xl pt-2">{{ content[indexContent].description }}</div>
            </div>
        </div>
        <UButton
            :to="content[indexContent].buttonLink"
            class="group-[.anim]:animate-slide-button backdrop-blur-xl mt-4 w-auto px-15 rounded-full"
            size="xl"
            block
        >
            {{ content[indexContent].buttonText }}
        </UButton>
    </div>
</template>

<script setup lang="ts">
const anim = ref(false);

export type ContentSlide = {
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
};

const props = withDefaults(
    defineProps<{
        content: ContentSlide[];
        index?: number;
        duration?: number;
    }>(),
    {
        index: 0,
        duration: 5000
    }
);

const indexContent = ref(props.index);
const styleTimer = computed(() => ({
    'animation-duration': `${props.duration}ms`
}));

function startAnimation() {
    anim.value = false;
    setTimeout(() => {
        indexContent.value = props.index;
        anim.value = true;
    }, 200);
}

watch(
    () => props.index,
    () => {
        startAnimation();
    }
);

onMounted(() => {
    anim.value = true;
});
</script>
