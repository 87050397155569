export const useMyWebauthn = (
    registerEndpoint: string = '/account/webauthn/register',
    authenticateEndpoint: string = '/account/webauthn/authenticate'
) => {
    const { register, authenticate } = useWebAuthn({
        useBrowserAutofill: false,
        registerEndpoint,
        authenticateEndpoint
    });
    return { register, authenticate };
};
