import { type AppPermissionKeys, hasPermissions as hasPerm, hasOneOfPermissions as HasOneOf, AppPermissions } from '~/utils/permissions';
import type { Permissions } from '~/types/db';

// Définir le type pour les objets retournés par buildAuthObject
type AuthObject<K extends keyof typeof AppPermissions> = {
    key: keyof (typeof AppPermissions)[K];
    value: boolean;
}[];

class PermissionsModifier {
    inEditMode = ref(false);
    private permissions: Ref<Permissions> = ref({
        id: '',
        key: '',
        name: '',
        createdAt: new Date()
    });
    private ModifiedPermissionsKey: Ref<string> = ref('');
    //private AppPMap = new Map<keyof typeof AppPermissions, AuthObject<keyof typeof AppPermissions>>()
    private appPermissionsMap = ref(new Map<keyof typeof AppPermissions, AuthObject<keyof typeof AppPermissions>>());

    setPermissions(permissions: Permissions) {
        this.permissions.value = permissions;
        this.ModifiedPermissionsKey.value = this.permissions.value.key;
        Object.keys(AppPermissions).forEach(key => {
            this.appPermissionsMap.value.set(
                key as AppPermissionKeys,
                this.buildAuthObject(this.permissions.value.key, key as keyof typeof AppPermissions)
            );
        });
    }

    resetPermissions() {
        this.setPermissions(this.permissions.value);
    }

    setPermissionValue<K extends keyof typeof AppPermissions>(key: K, permission: keyof (typeof AppPermissions)[K], val: boolean) {
        console.log(key, val);

        this.ModifiedPermissionsKey.value = val
            ? addPermission(this.ModifiedPermissionsKey.value, key, permission)
            : removePermission(this.ModifiedPermissionsKey.value, key, permission);

        const bb = this.buildAuthObject(this.ModifiedPermissionsKey.value, key);
        this.appPermissionsMap.value.set(key, bb);
    }

    async modifyPermissions() {
        console.log(this);
        const r = await $fetch<Permissions>('/api/permissions', {
            method: 'PATCH',
            body: {
                id: this.permissions.value.id,
                key: this.ModifiedPermissionsKey.value
            }
        });
        console.log(r);
        this.permissions.value = r;
        this.inEditMode.value = false;
    }

    get appPermissions() {
        return this.appPermissionsMap.value;
    }

    get permissionsId() {
        return this.permissions.value.id;
    }

    get permissionsKey() {
        return this.ModifiedPermissionsKey.value;
    }

    private buildAuthObject<K extends keyof typeof AppPermissions>(authToken: string, type: keyof typeof AppPermissions) {
        const grantedAppPermissions = getPermissions(authToken, type);
        return Object.keys(AppPermissions[type])
            .filter(key => isNaN(Number(key))) // Ignore les index numériques
            .map(
                key =>
                    ({
                        key: key as keyof (typeof AppPermissions)[K],
                        value: grantedAppPermissions.some(v => key === v)
                    }) as { key: keyof (typeof AppPermissions)[K]; value: boolean }
            );
    }
}

export const useAppPermissions = () => {
    const { clear, user, fetch } = useUserSession();

    const authT = ref(user.value?.roleKey || '0');

    function setValue<K extends keyof typeof AppPermissions>(key: K, permission: keyof (typeof AppPermissions)[K], val: boolean) {
        console.log(key, val);
        authT.value = val ? addPermission(authT.value, key, permission) : removePermission(authT.value, key, permission);
        const bb = buildAuthObject(authT.value, key);
        appPermissions.value.set(key, bb);
    }

    function buildAuthObject<K extends keyof typeof AppPermissions>(authToken: string, type: keyof typeof AppPermissions) {
        const grantedAppPermissions = getPermissions(authToken, type);
        return Object.keys(AppPermissions[type])
            .filter(key => isNaN(Number(key))) // Ignore les index numériques
            .map(
                key =>
                    ({
                        key: key as keyof (typeof AppPermissions)[K],
                        value: grantedAppPermissions.some(v => key === v)
                    }) as { key: keyof (typeof AppPermissions)[K]; value: boolean }
            );
    }

    // Définir le type pour les objets retournés par buildAuthObject
    type AuthObject<K extends keyof typeof AppPermissions> = {
        key: keyof (typeof AppPermissions)[K];
        value: boolean;
    }[];

    const AppPMap = new Map<keyof typeof AppPermissions, AuthObject<keyof typeof AppPermissions>>();

    Object.keys(AppPermissions).forEach(key => {
        AppPMap.set(key as AppPermissionKeys, buildAuthObject(authT.value, key as keyof typeof AppPermissions));
    });

    const appPermissions = ref(AppPMap);

    watch(user, () => {
        authT.value = user.value?.roleKey || '0';
        Object.keys(AppPermissions).forEach(key => {
            AppPMap.set(key as AppPermissionKeys, buildAuthObject(authT.value, key as keyof typeof AppPermissions));
        });
        appPermissions.value = AppPMap;
    });

    const hasPermissions = <T extends keyof typeof AppPermissions>(type: T, ...permissions: Array<keyof (typeof AppPermissions)[T]>): boolean => {
        return hasPerm(authT.value, type, ...permissions);
    };
    const hasOneOfPermissions = <T extends keyof typeof AppPermissions>(
        type: T,
        ...permissions: Array<keyof (typeof AppPermissions)[T]>
    ): boolean => {
        return HasOneOf(authT.value, type, ...permissions);
    };

    return {
        permissionsModifier: new PermissionsModifier(),
        appPermissions,
        setValue,
        authToken: authT,
        fetch,
        clear,
        user,
        hasPermissions,
        hasOneOfPermissions
    };
};
