<template>
    <picture :class="$attrs.class" class="relative">
        <source v-for="item of generateSource()" :key="item.media" :media="item?.media" :srcset="item?.srcset" />
        <img ref="imgRef" v-bind="inputAttrs" class="absolute inset-0 h-full w-full" :class="props.imageClass" />
        <ClientOnly>
            
        </ClientOnly>
    </picture>
</template>

<script lang="ts" setup>
import { joinURL } from 'ufo';

defineOptions({
    inheritAttrs: false
});

const attrs = useAttrs();

const inputAttrs = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const returnObj: { [key: string]: any } = {};
    // use const below
    for (const attr in attrs) {
        if (attr !== 'class') {
            returnObj[attr] = attrs[attr];
        }
    }
    return returnObj;
});

export interface AirImgVariant {
    breakpoint: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    /**
     * Whether to preserve animation frames from input files. Default is true. Setting it to false reduces animations to still images.
     * This setting is recommended when enlarging images or processing arbitrary user content, because large GIF animations can weigh tens or even hundreds of megabytes.
     * It is also useful to set anim:false when using format:"json" to get the response quicker without the number of frames.
     */
    anim?: boolean;
    /**
     * Background color to add underneath the image. Applies to images with transparency (for example, PNG) and images resized with fit=pad.
     * Accepts any CSS color using CSS4 modern syntax, such as %23RRGGBB, red, rgb(255 255 0) and rgba(255 255 0 100)
     *
     */
    background?: string;
    /**
     * Blur radius between 1 (slight blur) and 250 (maximum). Be aware that you cannot use this option to reliably obscure image content,
     * because savvy users can modify an image's URL and remove the blur option. Use Workers to control which options can be set.
     * The default is 0 (no blur).
     */
    blur?: number;

    /**
     * Increase brightness by a factor. A value of 1.0 equals no change, a value of 0.5 equals half brightness,
     * and a value of 2.0 equals twice as bright. 0 is ignored.
     */
    brightness?: number;
    /**
     * Slightly reduces latency on a cache miss by selecting a quickest-to-compress file format,
     * at a cost of increased file size and lower image quality.
     * It will usually override the format option and choose JPEG over WebP or AVIF.
     * We do not recommend using this option, except in unusual circumstances like resizing uncacheable dynamically-generated images.
     */
    compression?: 'fast' | 'none';
    /**
     * Increase contrast by a factor.
     * A value of 1.0 equals no change, a value of 0.5 equals low contrast,
     * and a value of 2.0 equals high contrast. 0 is ignored.
     */
    contrast?: number;
    /**
     * Device Pixel Ratio. Default is [1].
     * Multiplier for width/height that makes it easier to specify higher-DPI sizes
     * if [1, 1.8, 2] is provided, three url will be generated in the srcset: urlAndSpecsOfImage 1x, urlAndSpecsOfImage 1.8x, and urlAndSpecsOfImage 2x.
     * default is [1]
     */
    dpr?: number[];
    /**
     * Affects interpretation of width and height. All resizing modes preserve aspect ratio. Used as a string in Workers integration. Available modes are:
     * @scale-down
     *  Similar to contain, but the image is never enlarged.
     *  If the image is larger than given width or height, it will be resized. Otherwise its original size will be kept.
     * @contain
     * Image will be resized (shrunk or enlarged) to be as large as possible within the given width or height while preserving the aspect ratio.
     * If you only provide a single dimension (for example, only width), the image will be shrunk or enlarged to exactly match that dimension.
     * @cover
     * Resizes (shrinks or enlarges) to fill the entire area of width and height.
     * If the image has an aspect ratio different from the ratio of width and height, it will be cropped to fit.
     * @crop
     * Image will be shrunk and cropped to fit within the area specified by width and height. The image will not be enlarged.
     * For images smaller than the given dimensions, it is the same as scale-down.
     * For images larger than the given dimensions,it is the same as cover. See also trim
     * @pad
     * Resizes to the maximum size that fits within the given width and height, and then fills the remaining area with a background color (white by default).
     * This mode is not recommended, since you can achieve the same effect more efficiently with the contain mode and the CSS object-fit: contain property.
     *
     * default is crop
     */
    fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';

    format?: 'auto';
    /**
     * Increase exposure by a factor.
     * A value of 1.0 equals no change, a value of 0.5 darkens the image, and a value of 2.0 lightens the image.
     * 0 is ignored.
     */
    gamma?: number;
    /** When cropping with fit: "cover" and fit: "crop",
     * this parameter defines the side or point that should not be cropped.
     * Available options are:
     * @auto
     * Selects focal point based on saliency detection (using maximum symmetric surround algorithm).
     * @side
     * A side "left", "right", "top", "bottom" or [x,y] coordinates specified on a scale from 0.0 (top or left) to 1.0 (bottom or right), 0.5 being the center.
     */
    gravity?: 'auto' | 'top' | 'right' | 'bottom' | 'left' | [number, number];
    /**
     * Specifies maximum height of the image in pixels. Exact behavior depends on the fit mode
     */
    height?: number;
    /**
     * Specifies maximum width of the image. Exact behavior depends on the fit mode;
     * use the fit=scale-down option to ensure that the image will not be enlarged unnecessarily.
     */
    width?: number;
    /**
     * Controls amount of invisible metadata (EXIF data) that should be preserved.
     * Color profiles and EXIF rotation are applied to the image even if the metadata is discarded.
     * Note that if the Polish feature is enabled, all metadata may have been removed already and this option will have no effect.
     * @keep
     * Preserves most of EXIF metadata, including GPS location if present.
     * @copyright
     * Discard all metadata except EXIF copyright tag. This is the default behavior for JPEG images
     * @none
     * Discard all invisible EXIF metadata. Currently, WebP and PNG output formats always discard metadata.
     */
    metadata?: 'none' | 'copyright' | 'keep';

    onerror?: 'redirect';
    /**
     * Specifies quality for images in JPEG, WebP, and AVIF formats.
     * The quality is in a 1-100 scale, but useful values are between 50 (low quality, small file size) and 90
     * (high quality, large file size). 85 is the default. When using the PNG format, an explicit quality setting allows use of PNG8 (palette) variant of the format.
     */
    quality?: number;
    /**
     * Number of degrees (90, 180, or 270) to rotate the image by. width and height options refer to axes after rotation.
     */
    rotate?: '90' | '180' | '270';
    /**
     * Specifies strength of sharpening filter to apply to the image.
     * The value is a floating-point number between 0 (no sharpening, default) and 10 (maximum).
     * 1 is a recommended value for downscaled images.
     */
    sharpen?: number;

    /**
     * Specifies a number of pixels to cut off on each side.
     * Allows removal of borders or cutting out a specific fragment of an image.
     * Trimming is performed before resizing or rotation. Takes dpr into account.
     * For image transformations and Cloudflare Images, use as four numbers in pixels separated by a semicolon, in the form of top;right;bottom;left
     */
    trim?: string;
    /**image url */
    src?: string;

    // watermark?: {
    //     url: string;
    //     opacity?: number;
    //     width?: number;
    //     height?: number;
    //     fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
    //     gravity?: 'center' | 'top' | 'right' | 'bottom' | 'left' | 'auto';
    // };
}

interface Props extends Omit<AirImgVariant, 'breakpoint' | 'src'> {
    debug?: boolean;
    preload?: boolean;
    imageClass?: string;
    src: string;
    variant?: Array<AirImgVariant>;
    // defaultVariant: Array<AirImgVariant>;
    // smVariant?: Array<AirImgVariant>;
    // mdVariant?: Array<AirImgVariant>;
    // lgVariant?: Array<AirImgVariant>;
    // xlVariant?: Array<AirImgVariant>;
    // xxlVariant?: Array<AirImgVariant>;
    smBkp?: string;
    mdBkp?: string;
    lgBkp?: string;
    xlBkp?: string;
    xxlBkp?: string;
}

const props = withDefaults(defineProps<Props>(), {
    imageClass: 'object-cover object-center',
    debug: false,
    quality: 70,
    dpr: () => [1],
    variant: () => [],
    format: 'auto',
    fit: 'crop',
    sharpen: 0.5,
    smBkp: '640px',
    mdBkp: '768px',
    lgBkp: '1024px',
    xlBkp: '1280px',
    xxlBkp: '1536px'
});
let getInfo: unknown;

if (import.meta.dev && props.debug && import.meta.client) {
    const breakpoints = [
        { bkp: 'xxl', val: '(min-width:' + props.xxlBkp + ')' },
        { bkp: 'xl', val: '(min-width:' + props.xlBkp + ')' },
        { bkp: 'lg', val: '(min-width:' + props.lgBkp + ')' },
        { bkp: 'md', val: '(min-width:' + props.mdBkp + ')' },
        { bkp: 'sm', val: '(min-width:' + props.smBkp + ')' },
        { bkp: 'default', val: 'not all and (min-width:' + props.smBkp + ')' }
    ];
    const imgElem = useTemplateRef('imgRef');
    const imgSize = ref({
        W: imgElem.value?.naturalWidth || 0,
        H: imgElem.value?.naturalHeight || 0,
        rw: 0,
        rh: 0
    });
    const debugEl = useTemplateRef('debugEl');

    const windowWidth = ref<number>();
    const getWindowSize = () => {
        windowWidth.value = window.innerWidth;
    };

    onMounted(async () => {
        window.addEventListener('resize', getWindowSize);
        if (imgElem.value)
            imgElem.value.onload = async () => {
                imgSize.value.W = imgElem.value?.naturalWidth || 0;
                imgSize.value.H = imgElem.value?.naturalHeight || 0;
                getRealSize(imgElem.value?.currentSrc || '');
            };

        imgSize.value.W = imgElem.value?.width || 0;
        imgSize.value.H = imgElem.value?.naturalHeight || 0;
        getRealSize(imgElem.value?.currentSrc || '');
    });

    function getRealSize(src: string) {
        const realImage = new Image();
        realImage.onload = () => {
            imgSize.value.rw = realImage.width;
            imgSize.value.rh = realImage.height;
            realImage.onload = null;
        };
        realImage.src = src;
    }

    onUnmounted(() => {
        window.removeEventListener('resize', getWindowSize);
    });

    getInfo = computed(() => {
        if (debugEl.value && import.meta.client && import.meta.dev) {
            const { bkp, val } = breakpoints.find(({ val }) => window.matchMedia(val).matches) || { bkp: '', val: '' };
            return {
                'window W': windowWidth.value || window.innerWidth,
                bkp: `${bkp}, ${val}`,
                dpr: window.devicePixelRatio,
                imgElemSize: {
                    css: `w:${debugEl.value.offsetWidth} h:${debugEl.value.offsetHeight}`
                },
                imgSize: {
                    css: `w:${imgSize.value.W} h:${imgSize.value.H}`,
                    real: `w:${imgSize.value.rw} h:${imgSize.value.rh}`,
                    prop: `w:${props.variant.find(v => v.breakpoint === bkp)?.width || props.width} h:${props.variant.find(v => v.breakpoint === bkp)?.height || props.height}`
                }
            };
        }

        return null;
    });
}

if (props.preload) {
    useHead({
        link: generateSource().map(({ srcset, media }) => ({
            rel: 'preload',
            as: 'image',
            imagesrcset: srcset,
            media,
            fetchpriority: 'high'
        }))
    });
}

function generateSource() {
    const breakpoints = [
        { variant: props.variant.find(v => v.breakpoint === 'sm'), bkp: props.smBkp },
        { variant: props.variant.find(v => v.breakpoint === 'md'), bkp: props.mdBkp },
        { variant: props.variant.find(v => v.breakpoint === 'lg'), bkp: props.lgBkp },
        { variant: props.variant.find(v => v.breakpoint === 'xl'), bkp: props.xlBkp },
        { variant: props.variant.find(v => v.breakpoint === 'xxl'), bkp: props.xxlBkp }
    ];

    const usedBreakpoints = breakpoints.filter(v => v.variant !== undefined) as {
        variant: AirImgVariant;
        bkp: string;
    }[];
    const firstUsedBreakpoint = usedBreakpoints[0]?.bkp || '';
    const sources = usedBreakpoints.map(({ variant, bkp }, i, ar) => {
        const next = ar[i + 1];

        const mediaCondition =
            next && next.bkp
                ? `(min-width: ${bkp}) and (max-width: ${Number(next.bkp.split('px')[0]) - 1}px)` // Si next est défini
                : `(min-width: ${bkp})`; // Sinon, juste min-width

        return {
            srcset: generateSrcset(variant),
            media: mediaCondition
        };
    });

    // const sources = breakpoints
    //     .filter(({ variant }) => variant?.length)
    //     .map(({ variant, bkp }) => ({
    //         srcset: generateSrcset(variant),
    //         media: `(min-width: ${bkp})`
    //     }));

    sources.push({
        srcset: generateSrcset({ breakpoint: 'sm' }), // fake breakpoint to get default variant
        media: firstUsedBreakpoint ? `not all and (min-width: ${firstUsedBreakpoint})` : ''
    });

    return sources;
}

function generateSrcset(variant: AirImgVariant) {
    return (
        (variant.dpr || props.dpr)
            .map(dpr => {
                let gravity = Array.isArray(props.gravity) ? `gravity=${props.gravity?.join('x')}` : props.gravity ? `gravity=${props.gravity}` : '';
                gravity = Array.isArray(variant.gravity)
                    ? `gravity=${variant.gravity?.join('x')}`
                    : variant.gravity
                      ? `gravity=${variant.gravity}`
                      : gravity;

                const params = [
                    variant.width || props.width ? `w=${variant.width || props.width}` : '',
                    variant.height || props.height ? `h=${variant.height || props.height}` : '',
                    variant.blur || props.blur ? `blur=${variant.blur || props.blur}` : '',
                    variant.brightness || props.brightness ? `brightness=${variant.brightness || props.brightness}` : '',
                    variant.compression || props.compression ? `compression=${variant.compression || props.compression}` : '',
                    variant.contrast || props.contrast ? `contrast=${variant.contrast || props.contrast}` : '',
                    `dpr=${dpr}`,
                    variant.fit || props.fit ? `fit=${variant.fit || props.fit}` : '',
                    variant.format || props.format ? `format=${variant.format || props.format}` : '',
                    variant.gamma || props.gamma ? `gamma=${variant.gamma || props.gamma}` : '',
                    variant.metadata || props.metadata ? `metadata=${variant.metadata || props.metadata}` : '',
                    variant.onerror || props.onerror ? `onerror=${variant.onerror || props.onerror}` : '',
                    variant.quality || props.quality ? `quality=${variant.quality || props.quality}` : '',
                    variant.rotate || props.rotate ? `rotate=${variant.rotate || props.rotate}` : '',
                    variant.sharpen || props.sharpen ? `sharpen=${variant.sharpen || props.sharpen}` : '',
                    variant.trim || props.trim ? `trim=${variant.trim || props.trim}` : '',
                    gravity
                ]
                    .filter(Boolean)
                    .join(',');

                const url = joinURL('/_icf', params, variant.src || props.src || '');
                return variant.dpr || props.dpr ? `${url} ${dpr}x` : url;
            })
            .join(', ') || ''
    );
}
</script>

<style></style>
